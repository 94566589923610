import { useRouter } from 'next/router';

import { useStore as auctionStore } from '../../contexts/auction';
import useGQLQuery from './useGQLQuery';

const useAuction = (requestName, options) => {
	const { query } = useRouter();
	const { data: auctionData, dispatch: auctionDispatch } = auctionStore();

	const getRequestName = (n, r) => {
		if (requestName && requestName.indexOf('|') !== -1) { // ex. auction>items|auctions-new
			const parts = requestName.split('|');
			if (r === 'request_path') return parts[0]; // ex. auction>items

			return parts[1]; // ex. auctions-new
		}

		return n;
	};

	// Get auction list
	const { loading, result, error, setVariables } = useGQLQuery(getRequestName(requestName, 'request_nm'), getRequestName(requestName, 'request_path'), {}, { enabled: false, ...options });

	// Get auction id from url
	const getAuctionIdFromUrl = () => {
		if (query.urisegment) {
			const urisegmentParts = query.urisegment.split('-');

			if (urisegmentParts.length > 0) {
				return urisegmentParts[urisegmentParts.length - 1];
			}
		}

		return null;
	};

	// == Auction application

	// Is auction application editable

	const isAuctionApplicationEditable = (d) => {
		return (!d.auction_application_id || (d && ['10', '90'].includes(d.status_id))); // 10 = Draft, 90 = Rejected
	};

	return {
		getAuctionIdFromUrl,
		auctionData,
		auctionDispatch,
		setVariables,
		loading,
		result,
		error,
		isAuctionApplicationEditable
	};
};

export default useAuction;
